import React from 'react'
import Logo from '/public/images/nubrakes-logo@white.svg'
import Image from 'next/image'

const Home = () => {
  return (
    <div className="bg-black h-screen w-screen flex flex-col items-center justify-center">
      <a href={'https://nubrakes.com'}>
        <Image alt="NuBrakes Logo" width={'210'} src={Logo} className="d-inline-block align-top" />
      </a>
    </div>
  )
}

export default Home
